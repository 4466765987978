import React, { useEffect, useState } from 'react'
import { graphql, type HeadFC, type PageProps } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { HeaderAndFooterLayout } from '../../layouts/HeaderAndFooter'
import { css } from '../../../styled-system/css'
import Masonry from 'react-responsive-masonry'
import SEO from '../../components/SEO'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { scrollToTop } from '../../utils/scrollToTop'
import { useIsMobile } from '../../utils/useIsMobile'

const getWidth = () =>
  typeof window !== 'undefined'
    ? window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    : 0

const GalleryPage: React.FC<PageProps> = ({ data }) => {
  const queryData = data as Query
  const [colsAmount, setColsAmount] = useState(1)
  let [width, setWidth] = useState(getWidth())
  const [activeCategory, setActiveCategory] = useState<string | null>(null)
  const [storedPageId, setStoredPageId] = useState<string | null>('') // State to hold the retrieved ID
  
  const isMobile = useIsMobile();


  useEffect(() => {
    if (typeof window !== 'undefined') {
      const resizeListener = () => {
        const newWidth = getWidth()
        setWidth(newWidth)
        setColsAmount(newWidth > 900 ? 3 : newWidth > 600 ? 2 : 1)
      }
      window.addEventListener('resize', resizeListener)
      resizeListener()
      return () => {
        window.removeEventListener('resize', resizeListener)
      }
    }
  }, [])

  useEffect(() => {
    // Retrieve the stored ID from localStorage
    const storedPageId = localStorage.getItem('pageId')
    // Only set the active category if storedPageId is not null and not empty
    if (storedPageId && storedPageId.trim() !== '') {
      setActiveCategory(storedPageId)
      localStorage.removeItem('pageId')
    }
  }, [])

  const categorys = queryData.allContentfulPortfolioImages.edges.map(
    ({ node }) => {
      return node.category
    }
  )

  const unduplicatedCategorys = [...new Set(categorys)]

  const handleCategoryClick = (category: string) => {
    if (activeCategory === category) {
      return setActiveCategory(null)
    }

    setActiveCategory(category)
    scrollToTop();
  }

  const correctImagesToDisplay =
    activeCategory === null
      ? queryData.allContentfulPortfolioImages.edges
      : queryData.allContentfulPortfolioImages.edges.filter(
          ({ node }) => node.category === activeCategory
        )


    const breakpoints = useBreakpoint()
    const [isTop, setIsTop] = useState(true)
    
    let topCoordinateMenu = '70px';

    if (!breakpoints.xs && !breakpoints.sm) {
      topCoordinateMenu = isTop ? '154px' : '84px'
    }
    const galerieMenuStyle = css({
      position: 'fixed',
      padding: '10px',
      transition: 'all 0.3s ease-in-out',
      zIndex: '99999',
      height: '50px',
      width: '100%',
      backgroundColor: '#000',
      display: 'flex',
      justifyContent: isMobile ? 'flex-start' : 'center',
      alignItems: 'center',
      borderBottom: '1px solid #d9c58b',
      '@media (max-width: 768px)': {
        justifyContent: 'left',
      },
    });

    useEffect(() => {
      const handleScroll = () => {
        if (breakpoints.md) {
          return
        }
  
        const atTop = window.scrollY === 0
        setIsTop(atTop)
      }
  
      window.addEventListener('scroll', handleScroll)
  
      return () => window.removeEventListener('scroll', handleScroll)
    }, [breakpoints])
  
    useEffect(() => {
      const handleScroll = () => {
        if (breakpoints.md) {
          return
        }
  
        const atTop = window.scrollY === 0
        setIsTop(atTop)
      }
    });


  return (
    <HeaderAndFooterLayout>
      <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        flexDir: 'column',
        p: '20px 0',
        gap: '20px',
        margin: 'auto',
        overflow: 'hidden'
      })}
    >
      <nav
      style={{top: topCoordinateMenu}}
        className={`${css({
          overflowX: 'auto',
          overflowY: 'hidden'
        })} ${galerieMenuStyle} galerie-menu` }
      > 
          
          <ul
          className={css({
            display: 'flex',
            gap: '5px',
            textTransform: 'uppercase',
            fontFamily: 'Exo 2',
            fontWeight: 'bold',
            fontSize: '18px',
            flexWrap: 'no-wrap',
            flexDirection: 'row',
            p: '5px',
            justifyContent: isMobile ? 'flex-start' : 'center',
            alignItems: 'center',
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none', // Pour Chrome, Safari et Opera
            },
            '@media (max-width: 768px)': {
                fontSize: '14px',
                gap: '2px'
              },
          })}
        >
            {unduplicatedCategorys.map((category, idx) => {
              
            const isActive = activeCategory === category
            const isFirst = idx === 0;
              return (
              <li
                className={css({
                  padding: '10px 10px 10px 40px',
                  marginLeft: '10px',
                  cursor: 'pointer',
                  userSelect: 'none',
                  color: isActive ? '#fff' : '#ccc',
                  opacity: isActive ? '1' : '.5',
                  transition: '.3s',
                  pos: 'relative',
                  _before: {
                    content: !isFirst ? '""': '',
                    pos: 'absolute',
                    left: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: '6px', 
                    height: '6px', 
                    backgroundColor: '#d9c58b',
                    borderRadius: '50%'
                  },
                  '@media (max-width: 768px)': {
                    paddingLeft: '20px'
                  },
                })}
                
                key={idx}
                onClick={() => handleCategoryClick(category)}
              >
                <span className={css({
                  whiteSpace: 'nowrap',
                  borderBottom: isActive
                    ? '3px solid black'
                    : '3px solid transparent'})}>{category}</span>
              </li>
            )})}
          </ul>
          </nav>
        </div>

        <h1
          className={css({
            width: '100%',
            textAlign: 'center',
            fontSize: '2rem',
            fontWeight: 'bold',
            zIndex: '1',
            margin: '20px 0',
            '@media (max-width: 768px)': {
              fontSize: '30px',
            },
          })}>
          Galerie photo {activeCategory}
        </h1>
        <Masonry columnsCount={colsAmount} gutter="5px">
          
          {correctImagesToDisplay.map(({ node }) => {
            return (
              
                node.image ? (
                  <GatsbyImage
                    key={node.id}
                    className={css({
                      w: '100%',
                    })}
                    image={
                      getImage(node.image.gatsbyImageData) as IGatsbyImageData
                    }
                    alt={node.image.title || 'Portfolio Image'}
                  />
                ) : null
            )
          })}
        </Masonry>
      
    </HeaderAndFooterLayout>
  )
}

export const query = graphql`
  query MyQuery {
    allContentfulPortfolioImages {
      edges {
        node {
          id
          category
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
            title
            filename
          }
        }
      }
    }
  }
`

type Query = {
  allContentfulPortfolioImages: {
    edges: {
      node: {
        id: string
        category: string
        image: {
          gatsbyImageData: IGatsbyImageData
          filename: string
          title: string
        }
      }
    }[]
  }
}

export default GalleryPage

export const Head: HeadFC = () => (
  <SEO
    title="Galerie photo Digit Universe Photographie - Découvrez nos prestations photographiques"
    description="Explorez la galerie photo de Digit Universe Photographie à Lille. Découvrez nos prestations en photographie de grossesse, nouveau-nés, familles, couples, et événements. Immortalisez vos souvenirs inoubliables avec créativité et passion."
    robotsContent="index, follow"
  />
)
